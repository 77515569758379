// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.
import 'url-search-params-polyfill';
import 'whatwg-fetch';
import 'es6-promise-promise';


function handle_form(e) {

    e.preventDefault();

    // Let's validate
    var formValidate = require('./formValidate');
    if( formValidate.validate( document.getElementsByClassName('js-validate') ) ){
        let meta = document.getElementsByClassName('js-jsonify');

        let event_id = document.querySelector('input[name=event_id]').value;
        let event_name = document.querySelector('input[name=event_name]').value;
        let participant_meta = regiment_meta(meta);

        let data = {
            'event_id' : event_id,
            'event_name' : event_name,
            'participant_meta' : participant_meta
        }
        data = JSON.stringify(data);

        const requestBody = new URLSearchParams({ data: data, action: 'add_participant' }).toString();

        fetch(window.ajaxurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            },
            body: requestBody,
            credentials: 'same-origin'
        }).then(response => {
            var form = document.getElementById('js-form');
            form.style.display = 'none';
            if (response.status == 200) {
                var result = document.getElementById('js-result-success');
                result.classList.add('active');
            }
            else {
                var result = document.getElementById('js-result-error');
                result.classList.add('active');
            }
            return response.json();
        });

        return;
    }

}

// Parse the dynamic meta to establish our ontological commitments and then stringify them
function regiment_meta(participant_meta) {

    var regimented_participant_meta = {};
    for (var i = participant_meta.length - 1, item; item = participant_meta[i]; i--) {
        let name = item.name;
        let value = item.value;
        regimented_participant_meta[name] = value;
    }
    return regimented_participant_meta;

}

document.getElementById('js-submit').onclick = function(e) {handle_form(e)};